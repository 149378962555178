import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { VOID } from '@app/utils/operators/stream-utils';
import { getDialogOptions } from '@configs';
import { DialogComponent } from '@models';
import { InOutAnimation, SlideInAnimation } from '@shared/animations';
import { TestPackDef } from '@shared/modules/transform/models/test-pack-def.model';
import { TestPackGridSelection } from '@shared/modules/transform/models/test-pack-grid-selection.model';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
} from '@shared/modules/transform/models/transform-config.model';
import { TransformStorageService } from '@shared/modules/transform/services/transform-storage.service';
import {
  checkFileFactory,
  getFileContents,
  getMaxFileSize,
  stripExtension,
} from '@utils';
import { BehaviorSubject, Observable, map, switchMap } from 'rxjs';
import { TransientSample } from '../../models';

@Component({
  selector: 'app-transform-add-sample-dialog',
  templateUrl: './transform-add-sample-dialog.component.html',
  styleUrls: ['./transform-add-sample-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InOutAnimation, SlideInAnimation],
})
export class TransformAddSampleDialogComponent implements DialogComponent {
  constructor(
    private _storageService: TransformStorageService,
    private _dialogRef: MatDialogRef<
      TransformAddSampleDialogComponent,
      TestPackDef
    >,
    @Inject(MAT_DIALOG_DATA) public selection: TestPackGridSelection,
    @Inject(TRANSFORM_CONFIG) private _transformConfig: ITransformConfig
  ) {}

  private _errorMsgSubject$ = new BehaviorSubject<string | null>(null);
  private _canAddSampleSubject$ = new BehaviorSubject<boolean>(false);

  pipelineLabel = this._transformConfig.pipelineSelector.label;
  testPackLabel = this._transformConfig.testPackSelector.label;
  fileType = this.selection.pipelineDef.inputSerialisationFormat;
  sampleName: string;
  file: File | null = null;
  maxFileSizeMb = Math.floor(getMaxFileSize() / 1_000_000);
  storeOnServer = false;

  errorMsg$ = this._errorMsgSubject$.asObservable();
  canAddSample$ = this._canAddSampleSubject$.asObservable();

  static options(data: Partial<TestPackGridSelection>): MatDialogConfig {
    return getDialogOptions('md', {
      data,
    });
  }

  private _checkFileSize = checkFileFactory();

  onAddSample(): void {
    if (!this.file) {
      this._createErrorMessage(new Error('Please select a file to add.'));
      return;
    }

    this._createJsonSample(this.selection)
      .pipe(
        switchMap(transientSample =>
          this._storageService.addSample(
            this.selection,
            transientSample,
            this.storeOnServer
          )
        )
      )
      .subscribe({
        error: e => this._createErrorMessage(e),
        next: testPackDef => {
          if (testPackDef && !this._hasErrors()) {
            this._dialogRef.close(testPackDef);
          }
        },
      });
  }

  onFileDropped(files: FileList): void {
    this._clear();

    if (!this._checkFileSize(files, 'REST') && files.length) {
      this.file = files.item(0);
      this.sampleName = this.sampleName ?? stripExtension(this.file.name);
    }

    this._checkSampleFileExists();
  }

  private _clear(): void {
    this.file = null;
    this._errorMsgSubject$.next(null);
    this._canAddSampleSubject$.next(false);
  }

  private _hasErrors(): boolean {
    return this._errorMsgSubject$.getValue() !== null;
  }

  private _createErrorMessage(e: HttpErrorResponse | Error): Observable<void> {
    this._errorMsgSubject$.next(
      e instanceof HttpErrorResponse ? e.error.message : e.message
    );
    return VOID;
  }

  private _checkSampleFileExists(): void {
    this._canAddSampleSubject$.next(!!this.file);
  }

  private _createJsonSample({
    testPackDef,
  }: TestPackGridSelection): Observable<TransientSample> {
    return getFileContents(this.file).pipe(
      map(json => {
        return {
          testPackDef,
          sample: {
            sampleDef: {
              // TODO: We should not be setting an ID the same as the name,
              // this should be done where the sample is saved and a ID is assigned
              id: this.sampleName,
              name: this.sampleName,
            },
            json,
          },
        };
      })
    );
  }
}
