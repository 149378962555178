// TODO: Remove completely once Translate 1.5 is stable

import { ALPHA_ACCESS } from '@configs';
import * as TranslateActions from '@features/workspace/modules/engine/translate/store/actions';
import { FeatureTab } from '@models';

export const TranslateTab: FeatureTab = {
  label: 'Translate',
  name: 'translate',
  icon: 'exchange-alt',
  url: 'engine-translate',
  detailsPath: ['engine-translate', 'view'],
  action: TranslateActions.panelOpen(),
  groupId: 'pipeline',
  params: {
    labsFeature: 'alpha',
    ability: ALPHA_ACCESS,
  },
};
