import { AgGridModule } from '@ag-grid-community/angular';
import {
  AgGridEvent,
  BodyScrollEndEvent,
  ColDef,
  Column,
  ColumnApi,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModuleRegistry,
  RowClickedEvent,
  SortChangedEvent,
} from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import { ComponentStateService } from '@core/services';
import { StorageKey } from '@models';
import { Store } from '@ngrx/store';
import { rosettaTableCellComponentList } from '@shared/modules/rosetta-table/cell-components';
import { rosettaTableEditorComponentList } from '@shared/modules/rosetta-table/editor-components';
import { rosettaTableFilterComponentList } from '@shared/modules/rosetta-table/filter-components';
import {
  RosettaTableOptions,
  RosettaTableState,
} from '@shared/modules/rosetta-table/models/rosetta-table.model';
import { rosettaTableTooltipComponentList } from '@shared/modules/rosetta-table/tooltip-components';
import { AppSelectors } from '@store/selectors';
import { deepMerge, isObjectEmpty } from '@utils';
import { ReplaySubject, Subscription, combineLatest, map } from 'rxjs';
import { TextInputComponent } from '../text-input/text-input.component';
import { COLUMN_TYPE_IGNORE } from './rosetta-table.helpers';
import { TableColumnMenuComponent } from './menu-components/table-column-menu/table-column-menu.component';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontsModule,
    TableColumnMenuComponent,
    AgGridModule,
    TextInputComponent,
    MatButtonModule,
  ],
  selector: 'app-rosetta-table',
  templateUrl: './rosetta-table.component.html',
  styleUrls: [
    './rosetta-table.component.scss',
    './rosetta-table.component.queries.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'rosetta-table',
  },
})
export class RosettaTableComponent<TData = any, TContext = unknown>
  implements OnDestroy
{
  constructor(
    private _store: Store,
    private _cmpState: ComponentStateService<Partial<RosettaTableState>>,
    @Optional() @Attribute('id') tableId: string
  ) {
    this._setCacheKey(tableId);
  }

  gridApi!: GridApi;
  columnApi!: ColumnApi;
  context!: TContext;
  quickFilterText?: string;
  filterDisabled = false;
  cacheKey?: StorageKey;

  readonly components = {
    ...rosettaTableCellComponentList,
    ...rosettaTableTooltipComponentList,
    ...rosettaTableFilterComponentList,
    ...rosettaTableEditorComponentList,
  };

  readonly defaultGridOptions: GridOptions = {
    suppressCellFocus: true,
    suppressRowClickSelection: true,
    suppressColumnMoveAnimation: true,
    suppressLoadingOverlay: true,
    suppressClickEdit: true,
    rowBuffer: 10,
    columnTypes: {
      [COLUMN_TYPE_IGNORE]: {
        hide: true,
        initialHide: true,
        lockVisible: true,
      },
    },
  };
  readonly defaultColDefinitions: ColDef = {
    sortable: true,
    resizable: true,
    autoHeight: false,
    suppressMovable: true,
    minWidth: 100,
    getQuickFilterText: params => (params.colDef.hide ? '' : params.value),
  };

  @Output()
  gridReady = new EventEmitter<GridReadyEvent>();

  @Output()
  rowClicked = new EventEmitter<TData>();

  @Input() options: RosettaTableOptions = {};

  @Input() colDefs: ColDef<TData>[] = [];

  @Input() exportAsCsvColumnKeys?: (string | Column)[];

  @Input()
  set rowData(data: TData[] | undefined) {
    this._dataSubject.next(data);
  }

  @Input() set gridOptions(gridOptions: GridOptions) {
    this._gridOptions = deepMerge(this._gridOptions, gridOptions);
  }
  get gridOptions() {
    return this._gridOptions;
  }
  private _gridOptions = this.defaultGridOptions;

  @Input() set defaultColDef(colDef: ColDef<TData>) {
    this._defaultColDef = deepMerge(this._defaultColDef, colDef);
  }
  get defaultColDef() {
    return this._defaultColDef;
  }
  private _defaultColDef = this.defaultColDefinitions;

  private _sub = new Subscription();
  private _canUpdateState = false;
  private _dataSubject = new ReplaySubject<TData[] | undefined>(1);

  noData$ = this._dataSubject.pipe(map(data => !data || data.length === 0));
  classes$ = combineLatest([
    this.noData$.pipe(map(nodata => (nodata ? 'rosetta-table-empty' : ''))),
    this._store
      .select(AppSelectors.isDarkTheme)
      .pipe(map(isDark => `ag-theme-alpine${isDark ? '-dark' : ''}`)),
  ]);

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  onGridReady(params: GridReadyEvent<TData, TContext>) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.context = params.context;
    this._startDataUpdateListener();
    this.gridReady.emit(params);
  }

  updateFilter(filter: string) {
    this.quickFilterText = filter;

    if (this.cacheKey && this._canUpdateState) {
      this._cmpState.set(this.cacheKey, { quickFilterText: filter }, true);
    }
  }

  enableFilter() {
    this.filterDisabled = false;
  }

  disableFilter() {
    this.filterDisabled = true;
  }

  onRowClicked(event: RowClickedEvent) {
    this.rowClicked.emit(event.data);
  }

  downloadDataset() {
    const fileName =
      typeof this.options.getFileName === 'string'
        ? this.options.getFileName
        : this.options.getFileName?.();
    this.gridApi.exportDataAsCsv({
      fileName,
      columnKeys: this.exportAsCsvColumnKeys,
    });
  }

  onFilterChanged(event: FilterChangedEvent) {
    if (this.cacheKey && this._canUpdateState) {
      const filterModel = event.api.getFilterModel();

      if (!isObjectEmpty(filterModel)) {
        this._cmpState.set(this.cacheKey, { filterModel }, true);
      } else if (this._cmpState.has(this.cacheKey)) {
        const state = this._cmpState.get(this.cacheKey);
        delete state?.filterModel;
        this._cmpState.set(this.cacheKey, state);
      }
    }
  }

  onColumnState(event: SortChangedEvent) {
    if (this.cacheKey && this._canUpdateState) {
      const columnState = event.columnApi.getColumnState();
      this._cmpState.set(this.cacheKey, { columnState }, true);
    }
  }

  onBodyScrollEnd({ top, left }: BodyScrollEndEvent) {
    if (this.cacheKey && this._canUpdateState) {
      this._cmpState.set(this.cacheKey, { scrollState: { top, left } }, true);
    }
  }

  onFirstDataRendered(event: FirstDataRenderedEvent<TData>) {
    this._restoreState(event);
  }

  private _startDataUpdateListener() {
    this._sub.add(
      this._dataSubject.subscribe(data => {
        this.gridApi.setRowData(data || []);
      })
    );
  }

  private _restoreState(event: AgGridEvent<TData>) {
    const tableState = this._getTableStateCache();
    this._setFilterModel(event, tableState);
    this._setColumnState(event, tableState);
    this._setQuickFilterText(tableState);
    this._setRowScroll(event, tableState);
    this._setColumnScroll(event, tableState);
    this._canUpdateState = true;
  }

  private _getTableStateCache() {
    if (!this.cacheKey) {
      return {};
    }
    return this._cmpState.get(this.cacheKey) || {};
  }

  private _setCacheKey(tableId?: string) {
    if (tableId) {
      this.cacheKey = {
        key: `tbl-${tableId}`,
        global: this.options.globalState,
      };
    }
  }

  private _setFilterModel(
    event: AgGridEvent<TData>,
    { filterModel }: Partial<RosettaTableState>
  ) {
    if (filterModel) {
      event.api.setFilterModel(filterModel);
    }
  }

  private _setColumnState(
    event: AgGridEvent<TData>,
    { columnState }: Partial<RosettaTableState>
  ) {
    if (columnState) {
      event.columnApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  }

  private _setQuickFilterText({ quickFilterText }: Partial<RosettaTableState>) {
    if (quickFilterText) {
      this.quickFilterText = quickFilterText;
    }
  }

  private _setRowScroll(
    event: AgGridEvent<TData>,
    { scrollState }: Partial<RosettaTableState>
  ) {
    if (
      scrollState &&
      this.gridOptions.rowHeight &&
      scrollState.top > this.gridOptions.rowHeight
    ) {
      const rowIndex = Math.floor(scrollState.top / this.gridOptions.rowHeight);
      event.api.ensureIndexVisible(rowIndex, 'top');
    }
  }

  private _setColumnScroll(
    event: AgGridEvent<TData>,
    { scrollState }: Partial<RosettaTableState>
  ) {
    if (scrollState && scrollState.left) {
      const columns = event.columnApi.getAllDisplayedColumns();

      for (const column of columns) {
        if ((column.getLeft() || 0) >= scrollState.left) {
          event.api.ensureColumnVisible(column, 'start');
          break;
        }
      }
    }
  }
}
