import { NgModule } from '@angular/core';
import {
  FaConfig,
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import {
  faBell as farBell,
  faCheckSquare as farCheckSquare,
  faCircleXmark,
  faComments as farComments,
  faCopy as farCopy,
  faEdit as farEdit,
  faFile as farFile,
  faFileCode as farFileCode,
  faMap as farMap,
  faQuestionCircle as farQuestionCircle,
  faSquare as farSquare,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAdjust,
  faAlignJustify,
  faAngleDoubleUp,
  faArrowAltCircleUp,
  faArrowCircleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faAsterisk,
  faAward,
  faBalanceScale,
  faBan,
  faBars,
  faBolt,
  faBookReader,
  faBroadcastTower,
  faBuilding,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleArrowUp,
  faCircleNotch,
  faCloud,
  faCloudDownloadAlt,
  faCloudUploadAlt,
  faCode,
  faCodeBranch,
  faCodeMerge,
  faCog,
  faCompressAlt,
  faCrosshairs,
  faDownload,
  faEllipsisV,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpandAlt,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileDownload,
  faFileExport,
  faFileImport,
  faFilter,
  faFolder,
  faGears,
  faGift,
  faIdBadge,
  faInfoCircle,
  faHashtag,
  faLaptopCode,
  faLayerGroup,
  faLink,
  faList,
  faLock,
  faLockOpen,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faMedal,
  faMinus,
  faMinusSquare,
  faMoon,
  faPen,
  faPlay,
  faPlus,
  faPlusSquare,
  faProjectDiagram,
  faQuestionCircle,
  faRedo,
  faSave,
  faSearch,
  faSignOutAlt,
  faSitemap,
  faStar,
  faSun,
  faTableColumns,
  faTableList,
  faTag,
  faTasks,
  faTh,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTools,
  faTrash,
  faUndo,
  faUnlink,
  faUnlock,
  faUpload,
  faUser,
  faUserCog,
  faCirclePlus,
  faCircleMinus,
} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class FontsModule {
  constructor(library: FaIconLibrary, config: FaConfig) {
    config.fallbackIcon = faBan;
    library.addIcons(
      // Solid icons

      faAdjust,
      faAlignJustify,
      faAngleDoubleUp,
      faArrowAltCircleUp,
      faArrowCircleUp,
      faArrowDown,
      faArrowLeft,
      faArrowRight,
      faArrowUp,
      faAsterisk,
      faAward,
      faBalanceScale,
      faBars,
      faBolt,
      faBookReader,
      faBroadcastTower,
      faBuilding,
      faCaretDown,
      faCaretUp,
      faChartLine,
      faCheck,
      faCheckCircle,
      faChevronDown,
      faChevronLeft,
      faChevronRight,
      faChevronUp,
      faCircle,
      faCircleArrowUp,
      faCircleMinus,
      faCircleNotch,
      faCirclePlus,
      faCircleXmark,
      faCloud,
      faCloudDownloadAlt,
      faCloudUploadAlt,
      faCode,
      faCodeBranch,
      faCodeMerge,
      faCog,
      faCompressAlt,
      faCrosshairs,
      faDownload,
      faEllipsisV,
      faExchangeAlt,
      faExclamation,
      faExclamationCircle,
      faExclamationTriangle,
      faExpandAlt,
      faExternalLinkAlt,
      faEye,
      faEyeSlash,
      faFileAlt,
      faFileDownload,
      faFileExport,
      faFileImport,
      faFilter,
      faFolder,
      faGears,
      faGift,
      faIdBadge,
      faInfoCircle,
      faHashtag,
      faLaptopCode,
      faLayerGroup,
      faLink,
      faList,
      faLock,
      faLockOpen,
      faMagnifyingGlassMinus,
      faMagnifyingGlassPlus,
      faMedal,
      faMinus,
      faMinusSquare,
      faMoon,
      faPen,
      faPlay,
      faPlus,
      faPlusSquare,
      faProjectDiagram,
      faQuestionCircle,
      faRedo,
      faSave,
      faSearch,
      faSignOutAlt,
      faSitemap,
      faStar,
      faSun,
      faTableColumns,
      faTableList,
      faTag,
      faTasks,
      faTh,
      faThumbtack,
      faTimes,
      faTimesCircle,
      faTools,
      faTrash,
      faUndo,
      faUnlink,
      faUnlock,
      faUpload,
      faUser,
      faUserCog,

      // Brands icons
      faGithub,

      // Regular icons
      farBell,
      farCheckSquare,
      farComments,
      farCopy,
      farEdit,
      farFile,
      farFileCode,
      farMap,
      farQuestionCircle,
      farSquare
    );
  }
}
