import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { getValueToDisplay } from '../../utils/rosetta-table-utils';

@Component({
  standalone: true,
  selector: 'app-text-cell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
  styles: [
    `
      @use 'helpers';

      :host {
        @extend .text-ellipsis;

        display: flex;
        flex-direction: column;
        justify-content: center;
        height: var(--ag-internal-padded-row-height);
        line-height: normal;
      }
    `,
  ],
})
export class TextCellComponent implements ICellRendererAngularComp {
  constructor(private _elementRef: ElementRef) {}

  agInit(params: ICellRendererParams) {
    this._elementRef.nativeElement.insertAdjacentHTML(
      'beforeend',
      getValueToDisplay(params)
    );
  }

  refresh(): boolean {
    return false;
  }
}
