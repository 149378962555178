import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FontsModule } from '@app/fonts/fonts.module';
import { LetDirective } from '@ngrx/component';
import {
  ClickToOpenUpgradeDialogDirective,
  HasAbilityDirective,
  ShowUpgradeOfferDirective,
} from '@shared/directives';
import {
  LoadingSpinnerModule,
  TextBadgeModule,
  ThemeToggleModule,
} from '@shared/modules';
import { InlineErrorModule } from '@shared/modules/inline-error/inline-error.module';
import { LogoModule } from '@shared/modules/logo/logo.module';
import { RosettaMenuComponent } from '@shared/modules/rosetta-menu.component';
import { RosettaOverlayModule } from '@shared/modules/rosetta-overlay/rosetta-overlay.modules';
import { UpgradePlanButtonComponent } from '@shared/modules/upgrade-plan-button.component';
import { HelpMenuComponent } from './components/help-menu/help-menu.component';
import { NotificationMenuComponent } from './components/notification-menu/notification-menu.component';
import { TextComponent } from './components/text/text.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { ViewContainerRefDirective } from './directives/view-container-ref.directive';
import { NavbarComponent } from './pages/navbar.component';

@NgModule({
  declarations: [
    NavbarComponent,
    HelpMenuComponent,
    NotificationMenuComponent,
    TextComponent,
    UserMenuComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontsModule,

    // Third Party
    LetDirective,

    // UI Modules
    RosettaOverlayModule,
    ThemeToggleModule,
    TextBadgeModule,
    InlineErrorModule,
    LogoModule,
    LoadingSpinnerModule,

    // Material
    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatMenuModule,
    MatToolbarModule,

    // Standalone
    ViewContainerRefDirective,
    RosettaMenuComponent,
    UpgradePlanButtonComponent,
    ShowUpgradeOfferDirective,
    ClickToOpenUpgradeDialogDirective,
    HasAbilityDirective,
  ],
})
export class NavbarModule {}
