import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { RosettaTourService } from '@shared/modules/rosetta-tour/rosetta-tour.service';
import { TransformAddSampleDialogComponent } from '@shared/modules/transform/components/transform-add-sample-dialog/transform-add-sample-dialog.component';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { TransformSelectorManagerService } from '@shared/modules/transform/services/selectors/transform-selector-manager.service';
import { AppActions } from '@store/.';
import { isString } from '@utils';
import { combineLatest, first, map, startWith } from 'rxjs';
import { PipelineRunData, PipelineRunInfo, SampleRowState } from '../../models';
import { TestPackDef } from '../../models/test-pack-def.model';
import { TestPackGridSelection } from '../../models/test-pack-grid-selection.model';
import { TRANSFORM_UPLOAD } from '../../models/transform.const';
import { TransformService } from '../../services/transform.service';
import { transformTourSteps } from '../../transform.tour';

@Component({
  selector: 'app-transform-list',
  templateUrl: './transform-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'transform-list' },
})
export class TransformListComponent implements OnInit, OnDestroy {
  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _rosettaTourService: RosettaTourService,
    public transformService: TransformService,
    public transformSelectorManager: TransformSelectorManagerService
  ) {}

  private _isTestPackSelectionValid$ =
    this.transformSelectorManager.testPackGridSelection$.pipe(
      map(selection => !!selection),
      startWith(false)
    );

  // Wait for isSupported$ to return before waiting for workspace ready
  // to prevent UI displaying the unsupported screen unnecessarily
  waitForWorkspaceReady$ = this.transformService.workspaceReadyForTransform$;

  // @deprecated - remove when upload test pack is removed
  isUploadTestPacks$ = this.transformSelectorManager.testPackSelected$.pipe(
    map(testPackSelected => testPackSelected?.name === TRANSFORM_UPLOAD),
    startWith(false)
  );

  canAddSample$ = combineLatest([
    this._isTestPackSelectionValid$,
    this.isUploadTestPacks$,
    this.transformService.runResultState$.pipe(startWith(null)),
  ]).pipe(
    map(
      ([testPackSelectionValid, isUploadTestPack, runResultState]) =>
        !!testPackSelectionValid &&
        !isUploadTestPack &&
        this._isRunComplete(runResultState)
    )
  );

  ngOnInit(): void {
    this.waitForWorkspaceReady$.pipe(first()).subscribe(() => {
      this.transformSelectorManager.init();
      this._rosettaTourService.loadTourSteps(transformTourSteps);
      this._rosettaTourService.start();
    });
  }

  ngOnDestroy(): void {
    this.transformSelectorManager.cleanup();
  }

  onRowClicked(row: transform.DataViewerRow): void {
    if (row.sampleRowState === SampleRowState.Errored) {
      return;
    }

    this.transformService.goToTransformDetails(
      row.testPackGridSelection,
      row.sampleId
    );
  }

  onRowEvent(event: transform.DataViewerEvent): void {
    this.transformService.processTransformEvent(event).subscribe({
      next: () => {
        this._store.dispatch(
          AppActions.showBasicSuccessMsg({
            message: `${event.type} success on ${event.rows.length} sample${event.rows.length > 1 ? 's' : ''}`,
          })
        );
      },
      error: e => {
        this._store.dispatch(
          AppActions.showBasicErrorMsg({ message: e.message })
        );
      },
    });
  }

  openAddSampleDialog(): void {
    const selection = this.transformSelectorManager.getCurrentSelection();
    const component = TransformAddSampleDialogComponent;
    this._dialog
      .open<
        TransformAddSampleDialogComponent,
        TestPackGridSelection,
        TestPackDef
      >(component, component.options(selection))
      .afterClosed()
      .pipe(first())
      .subscribe(testPackDef => {
        if (testPackDef && !isString(testPackDef)) {
          this.transformSelectorManager.refreshTestPacks(testPackDef);
        }
      });
  }

  private _isRunComplete(
    pipelineRunInfo: PipelineRunInfo<PipelineRunData> | null
  ): boolean {
    if (pipelineRunInfo === null) {
      return true;
    }

    if (
      pipelineRunInfo.result === null ||
      pipelineRunInfo.result.data === null
    ) {
      return false;
    }

    const { currentPipeline, totalPipelines } = pipelineRunInfo.result.details;
    return currentPipeline === totalPipelines;
  }
}
