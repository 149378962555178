import { Injectable } from '@angular/core';
import { TRANSLATE_CONFIG } from '@configs';
import { RunningState, Task, TranslateIngestionFile } from '@models';
import { Store } from '@ngrx/store';
import { TaskActions } from '@store/.';
import {
  TranslateActions,
  TranslateSelectors,
} from '@workspace-engine/translate/store';
import { filter, first, map, Observable, share, takeWhile, tap } from 'rxjs';

/**
 * @deprecated Use Transform module
 */

@Injectable({
  providedIn: 'root',
})
export class TranslateRunService {
  constructor(private _store: Store) {}

  private _queue: string[] = [];

  private _queueWatcher$ = this._store
    .select(TranslateSelectors.selectRunState)
    .pipe(
      takeWhile(() => !this.isEmpty),
      filter(runState => runState === RunningState.READY && !this.isEmpty),
      tap(() => this._sendQueuedRequest()),
      share()
    );

  requestRun(requests: TranslateIngestionFile[]): Observable<void> {
    return this._store.select(TranslateSelectors.selectRunState).pipe(
      first(),
      map(runState =>
        runState === RunningState.READY
          ? this._sendRunRequest(requests)
          : this._queueRequest(requests)
      )
    );
  }

  get isEmpty(): boolean {
    return this.length === 0;
  }

  get length(): number {
    return this._queue.length;
  }

  private _sendQueuedRequest() {
    this._store.dispatch(
      TranslateActions.runSampleFiles({ nodeIds: this._takeAll() })
    );
  }

  private _sendRunRequest(translateIngestionFiles: TranslateIngestionFile[]) {
    const batch = translateIngestionFiles.splice(0, TRANSLATE_CONFIG.batchSize);

    if (translateIngestionFiles.length > 0) {
      this._queueRequest(translateIngestionFiles);
    }

    this._store.dispatch(TranslateActions.runSampleFilesRequestSent());
    this._store.dispatch(
      TaskActions.submitTask({
        data: {
          task: Task.TranslateIngestSubmission,
          payload: {
            translateIngestionFiles: batch,
          },
        },
      })
    );
  }

  private _queueRequest(requests: TranslateIngestionFile[]) {
    this._store.dispatch(TranslateActions.runSampleFilesRequestQueued());
    this._enqueue(requests);
    this._queueWatcher$.subscribe();
  }

  private _enqueue(items: TranslateIngestionFile[]) {
    this._queue.push(...items.map(item => item.id));
  }

  private _takeAll(): string[] {
    const items = [...this._queue];
    this._queue = [];
    return items;
  }
}
