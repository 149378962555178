import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseWorkspaceService } from '@core/services';
import { RosettaNavigationService } from '@core/services/rosetta-navigation.service';
import { SupportedFeatureService } from '@features/workspace/services/supported-feature.service';
import { Task, TaskStatus } from '@models';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { currentWorkspaceIdObserver, isNotNull } from '@utils';
import { kebabCase } from 'lodash-es';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  distinctUntilChanged,
  filter,
  first,
  last,
  map,
  merge,
  share,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { ModifyExpectationDialogComponent } from '../components/modify-expectation-dialog/modify-expectation-dialog.component';
import { pipelineCatchError } from '../helpers/pipeline-catch-error.helper';
import { isTranslate_1_5 } from '../helpers/translate-1-5.helpers';
import { PipelineRunData, PipelineRunInfo } from '../models';
import { SampleRowAction } from '../models/sample-action.enum';
import { TestPackGridSelection } from '../models/test-pack-grid-selection.model';
import { TestPackGrid } from '../models/test-pack-grid.model';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
  TransformType,
} from '../models/transform-config.model';
import { TRANSFORM_UPLOAD } from '../models/transform.const';
import { TransformSelectorManagerService } from './selectors/transform-selector-manager.service';
import { TransformStorageService } from './transform-storage.service';

@Injectable()
export class TransformService extends BaseWorkspaceService {
  constructor(
    private _dialog: MatDialog,
    private _navigate: RosettaNavigationService,
    private _transformStorage: TransformStorageService,
    private _transformSelectorManager: TransformSelectorManagerService,
    private _supportFeatureService: SupportedFeatureService,
    @Inject(TRANSFORM_CONFIG) private _transformConfig: ITransformConfig
  ) {
    super();
    this.initWorkspaceObserver();
    this._setTransformTypeName();
  }

  private _pipelineCurrentRunResult = new Subject<
    PipelineRunInfo<PipelineRunData>
  >();
  private _rerunSubject = new Subject<TestPackGridSelection | null>();
  private _fileName: string;

  private _completeSelection$ =
    this._transformSelectorManager.testPackGridSelection$.pipe(
      map(selection => this._handleSelection(selection)),
      share<TestPackGridSelection | null>()
    );

  private _runResult$ = merge(
    this._completeSelection$,
    this._rerunSubject
  ).pipe(
    filter(isNotNull),
    switchMap((testPackGridSelection: TestPackGridSelection) =>
      currentWorkspaceIdObserver(this._store, {
        taskToWatch: Task.ExecutionEngineInitialisation,
      }).pipe(
        switchMap(() =>
          this._runTestPackPipelineAndGetResult(testPackGridSelection)
        )
      )
    )
  );
  private _emptyResult$: Observable<PipelineRunInfo> =
    this._completeSelection$.pipe(
      filter(selection => selection === null),
      map(() => ({ result: null, errorMessage: null }))
    );

  markRunAsStale$ = this._store
    .select(WorkspaceSelectors.getWorkspaceTaskStatus)
    .pipe(
      distinctUntilChanged(),
      filter(status => status === TaskStatus.Started),
      map(() => {
        const data: PipelineRunInfo = {
          result: null,
          errorMessage: null,
          stale: true,
        };

        return data;
      })
    );

  resultSource$: Observable<PipelineRunInfo<TestPackGrid>> = merge(
    this._runResult$,
    this._emptyResult$
  );

  isSupported$ = this._supportFeatureService.checkSupportFor(
    this._transformConfig.featureTabName
  );

  workspaceReadyForTransform$ = this.isSupported$.pipe(
    switchMap(() =>
      currentWorkspaceIdObserver(this._store, {
        taskToWatch: Task.ExecutionEngineInitialisation,
        waitForStatusList: [TaskStatus.Finished],
      })
    )
  );

  transformTypeName!: string;
  runResultState$ = merge(
    this._pipelineCurrentRunResult,
    this.markRunAsStale$
  ).pipe(share());

  getFileName = (): string => {
    return this._fileName || `${this.transformTypeName.toLowerCase()}-download`;
  };

  rerun(): void {
    this._rerunSubject.next(
      this._transformSelectorManager.testPackGridSelection
    );
  }

  goToTransformDetails(
    testPackGridSelection: TestPackGridSelection,
    sampleId: string
  ): void {
    const { pipelineDef, testPackDef } = testPackGridSelection;
    this._navigate.updateBottomPanel([
      this._transformConfig.url,
      pipelineDef.id,
      testPackDef.id,
      sampleId,
    ]);
  }

  processTransformEvent(event: transform.DataViewerEvent): Observable<void> {
    const actionTypeMap = {
      [SampleRowAction.Accept]: this._confirmUpdateDiffs,
      [SampleRowAction.Revert]: this._confirmRevertDiffs,
      [SampleRowAction.Delete]: this._deleteSample,
    } as const;

    if (!actionTypeMap[event.type]) {
      throw new Error('Unknown action type: ' + event.type);
    }

    return actionTypeMap[event.type].apply(this, [event]);
  }

  protected _onWorkspaceSwitch(): void {
    this._transformSelectorManager.resetAll();
    this._pipelineCurrentRunResult.next(null);
    this._rerunSubject.next(null);
    this._transformSelectorManager.cleanup();
  }

  private _setTransformTypeName(): void {
    if (this._transformConfig.type === TransformType.Translate_1_5) {
      this.transformTypeName = 'Ingest';
      return;
    }
    this.transformTypeName = this._transformConfig.type;
  }

  private _setFileName(selection: TestPackGridSelection): void {
    this._fileName = kebabCase(
      `${selection.pipelineDef.name}-${selection.testPackDef.name}`
    );
  }

  private _sampleIdsFromEvent(event: transform.DataViewerEvent): string[] {
    return event.rows.map(({ sampleId }) => sampleId);
  }

  private _firstSampleFromEvent(event: transform.DataViewerEvent): string {
    return event.rows[0].sampleId;
  }

  private _modifyConfirmationDialog<T>(
    event: transform.DataViewerEvent,
    stream$: Observable<T>
  ): Observable<T> {
    const comp = ModifyExpectationDialogComponent;
    return this._dialog
      .open<
        ModifyExpectationDialogComponent,
        transform.DataViewerEvent,
        boolean
      >(comp, comp.options(event))
      .afterClosed()
      .pipe(switchMap(result => (result ? stream$.pipe(first()) : EMPTY)));
  }

  private _confirmUpdateDiffs(
    event: transform.DataViewerEvent
  ): Observable<void> {
    return this._modifyConfirmationDialog(
      event,
      this._transformStorage
        .updateSample(
          event.testPackGridSelection,
          this._sampleIdsFromEvent(event)
        )
        .pipe(
          tap(() => this.rerun()),
          catchError(() =>
            throwError(() => new Error('Samples could not be updated'))
          )
        )
    );
  }

  private _confirmRevertDiffs(
    event: transform.DataViewerEvent
  ): Observable<void> {
    return this._modifyConfirmationDialog(
      event,
      this._transformStorage
        .revertSample(
          event.testPackGridSelection,
          this._sampleIdsFromEvent(event)
        )
        .pipe(
          tap(() => this.rerun()),
          catchError(() =>
            throwError(() => new Error('Samples could not be reverted'))
          )
        )
    );
  }

  private _deleteSample(event: transform.DataViewerEvent): Observable<void> {
    if (event.rows.length > 1) {
      throw new Error(
        'Unable to delete due to multiple samples: ' + event.rows.length
      );
    }

    return this._modifyConfirmationDialog(
      event,
      this._transformStorage
        .deleteSample(
          event.testPackGridSelection,
          this._firstSampleFromEvent(event)
        )
        .pipe(
          tap(() => this.rerun()),
          catchError(error =>
            throwError(
              () =>
                new Error(
                  `Unable to delete sample from '${event.testPackGridSelection.pipelineDef.name}/${event.testPackGridSelection.testPackDef.name}' due to an unexpected error: ${error}`
                )
            )
          )
        )
    );
  }

  private _runTestPackPipelineAndGetResult(
    testPackGridSelection: TestPackGridSelection
  ): Observable<PipelineRunInfo<TestPackGrid>> {
    return this._getRunStream(testPackGridSelection).pipe(
      pipelineCatchError(),
      tap(runResult => this._pipelineCurrentRunResult.next(runResult)),
      last()
    );
  }

  private _getRunStream(
    testPackGridSelection: TestPackGridSelection
  ): Observable<PipelineRunInfo<PipelineRunData>> {
    if (isTranslate_1_5(testPackGridSelection)) {
      return this._transformStorage.runTranslate_1_5(testPackGridSelection);
    } else if (testPackGridSelection.testPackDef.name === TRANSFORM_UPLOAD) {
      return this._transformStorage.runUploadTestPack(testPackGridSelection);
    }
    return this._transformStorage.runTestPackPipeline(testPackGridSelection);
  }

  private _handleSelection(
    selection: TestPackGridSelection | null
  ): TestPackGridSelection | null {
    if (selection) {
      this._setFileName(selection);
    } else {
      this._pipelineCurrentRunResult.next(null);
    }
    return selection;
  }
}
