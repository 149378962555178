import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FontsModule } from '@app/fonts/fonts.module';
import { StripHtmlModule } from '@shared/modules/strip-html/strip-html.module';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { NotificationService } from './services/notification.service';

@NgModule({
  declarations: [SnackBarComponent],
  providers: [NotificationService],
  imports: [
    CommonModule,
    FontsModule,

    // UI Modules
    StripHtmlModule,

    // Material
    MatSnackBarModule,
    MatButtonModule,
  ],
})
export class SnackBarModule {}
