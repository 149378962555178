import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { TaskStatus } from '@models';
import { TranslateActions } from '@workspace-engine/translate/store';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { createFormDataWithFile, deleteHttpPostFallback } from '@utils';
import {
  catchError,
  combineLatest,
  defer,
  first,
  iif,
  map,
  mergeMap,
  Observable,
  switchMap,
  throwError,
} from 'rxjs';
import { EnvironmentConfig, ResponseSampleFileNode, TestType } from '../models';
import { SampleFileNodeFull } from '../models/sample-file-node.model';
import { TranslateUploadStorageService } from './translate-storage/translate-upload-storage.service';

/**
 * @deprecated Use Transform module
 */

@Injectable({
  providedIn: 'root',
})
export class TranslateApiService {
  constructor(
    private _http: HttpClient,
    private _storage: TranslateUploadStorageService,
    private _store: Store,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  getEnvironmentConfig(): Observable<EnvironmentConfig[]> {
    return this._store.pipe(
      WorkspaceSelectors.getWorkspaceId,
      first(),
      switchMap(workspaceId =>
        this._http.get<EnvironmentConfig[]>(
          `${this._config.resourcePaths.translate}/environment-config/${workspaceId.name}`
        )
      )
    );
  }

  get(): Observable<ResponseSampleFileNode[]> {
    const filesFromServer$ = this._store
      .pipe(WorkspaceSelectors.getWorkspaceId)
      .pipe(
        first(),
        switchMap(workspaceId =>
          this._http.get<ResponseSampleFileNode[]>(
            `${this._config.resourcePaths.translate}/sample-file-nodes/${workspaceId.name}`
          )
        )
      );

    return combineLatest([this._storage.getAll(), filesFromServer$]).pipe(
      map(([storageFiles, apiFiles]) => storageFiles.concat(apiFiles))
    );
  }

  upload(
    file: File,
    selectedEnv: string,
    uploadToServer = false
  ): Observable<ResponseSampleFileNode> {
    if (uploadToServer) {
      return this._storeOnServer(file, selectedEnv);
    }
    return this._storeOnClient(file, selectedEnv);
  }

  delete(node: SampleFileNodeFull): Observable<SampleFileNodeFull> {
    return iif(
      () => node.testType === TestType.UPLOADED_TO_CLIENT,
      defer(() => this._storage.delete(node.id)),
      this._store.pipe(
        WorkspaceSelectors.getWorkspaceId,
        switchMap(workspaceId =>
          this._http
            .delete(
              `${this._config.resourcePaths.translate}/sample-file-nodes/${
                workspaceId.name
              }/${encodeURIComponent(node.id)}`
            )
            .pipe(deleteHttpPostFallback(this._http))
        )
      )
    ).pipe(
      map(() => ({
        ...node,
        state: TaskStatus.Deleted,
      }))
    );
  }

  private _storeOnClient(
    file: File,
    selectedEnv: string
  ): Observable<ResponseSampleFileNode> {
    this._store.dispatch(TranslateActions.uploadSampleFile());
    return this._http
      .post(
        `${this._config.resourcePaths.translate}/sample-file-nodes/validate-file`,
        createFormDataWithFile(file)
      )
      .pipe(
        catchError(this._errorHandler),
        switchMap(() => this._storage.set(file, selectedEnv))
      );
  }

  private _storeOnServer(
    file: File,
    selectedEnv: string
  ): Observable<ResponseSampleFileNode> {
    this._store.dispatch(TranslateActions.uploadSampleFile());

    return this._store.pipe(WorkspaceSelectors.getWorkspaceId).pipe(
      mergeMap(workspaceId => {
        return this._http.post<ResponseSampleFileNode>(
          `${this._config.resourcePaths.translate}/sample-file-nodes/${workspaceId.name}?env=${selectedEnv}`,
          createFormDataWithFile(file)
        );
      }),
      catchError(this._errorHandler)
    );
  }

  private _errorHandler(response: HttpErrorResponse) {
    return throwError(() => response.error);
  }
}
