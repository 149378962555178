<form [formGroup]="form">
  <ng-container formArrayName="selectors">
    <ng-container *ngFor="let selectorControl of formSelectors; let i = index">
      <mat-form-field
        [tourAnchor]="selectors[i].tourAnchor"
        appearance="outline"
        [floatLabel]="'always'"
        [ngClass]="{ 'auto-width': !!selectorControl.value }"
      >
        <mat-label>{{ selectors[i].label }}</mat-label>
        <fa-icon
          *ngIf="selectors[i].dataLoading$ | async"
          @inOutAnimation
          matIconPrefix
          icon="circle-notch"
          class="theme-color-primary pl-4 pr-4"
          [classes]="['fa-spin']"
          [ngStyle]="{
            '--fa-animation-duration': '.9s',
          }"
        />
        <mat-select
          #selector
          *ngrxLet="selectors[i].options$ | async as options"
          [placeholder]="
            options?.length > 0
              ? selectors[i].placeholder
              : 'No options to select'
          "
          [formControl]="selectorControl"
          (valueChange)="onValueChanged($event, i)"
          (openedChange)="onOpenChanged($event, i)"
        >
          <mat-option *ngFor="let option of options" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </ng-container>
</form>
