import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { FontsModule } from '@app/fonts/fonts.module';
import { Project, UserDetails } from '@features/auth/login';
import { IDomainModelDetails } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { MODEL_DATA$ } from '@shared/modules/model-pipe/model.pipe';
import { ProjectRowModule } from '@shared/modules/project-row/project-row.module';
import { RosettaMenuComponent } from '@shared/modules/rosetta-menu.component';
import { RosettaOverlayModule } from '@shared/modules/rosetta-overlay/rosetta-overlay.modules';
import { Observable } from 'rxjs';
import {
  ProjectCellComponent,
  ProjectCellParams,
} from '../../cell-components/project-cell/project-cell.component';
import { MatListModule } from '@angular/material/list';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FontsModule,
    FormsModule,
    MatListModule,
    MatSelectModule,
    ProjectCellComponent,
    ProjectRowModule,
    RosettaMenuComponent,
    RosettaOverlayModule,
  ],
  selector: 'app-project-editor',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './project-editor.component.html',
  styleUrls: ['./project-editor.component.scss'],
})
export class ProjectEditorComponent implements ICellEditorAngularComp {
  constructor(
    @Inject(MODEL_DATA$)
    private _modelData$: Observable<Map<ModelInstanceId, IDomainModelDetails>>
  ) {}

  params!: ICellEditorParams<UserDetails>;
  modelIds: string[] = [];
  projects$ = this._modelData$;

  compareWithFunc = (
    model1: IDomainModelDetails,
    model2: IDomainModelDetails
  ) => {
    return (model1?.id || model1) === (model2?.id || model2);
  };

  agInit(params: ICellEditorParams & ProjectCellParams) {
    this.params = params;
    this.modelIds =
      (params.getModelIds && params.getModelIds(params.data)) || [];
  }

  getValue(): Project[] {
    return this.modelIds.map(
      id =>
        this.params.data.projects.find(p => p.id === id) || { id, owner: false }
    );
  }

  isPopup(): boolean {
    return false;
  }

  setModelIds(projects: Project[]) {
    this.modelIds = projects.map(({ id }) => id);
  }
}
