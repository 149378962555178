import { AgGridEvent, RowNode } from '@ag-grid-community/core';
import { DiagnosticResultKey } from '@shared/modules/diagnostic-panel/diagnostic.model';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { SampleCellState } from '@shared/modules/transform/models/sample-cell-state.enum';
import { TransformDTO } from '@shared/modules/transform/models/transform-dto.model';

import {
  DiagnosticsSummaryData,
  DiagnosticSummary,
} from '../../diagnostic-chart/diagnostic.models';

export const updateSummary = ({
  api,
  columnApi,
  context,
}: AgGridEvent<transform.DataViewerRow, transform.DataViewerContext>) => {
  const cells: transform.DataViewerCell[] = [];
  const diagnostics: TransformDTO.Diagnostics[] = [];

  api.getModel().forEachNode((rowNode: RowNode<transform.DataViewerRow>) => {
    // Excludes rows which are filtered out
    if (rowNode.displayed) {
      const rowCells = [...(rowNode.data?.cellMap.values() || [])];
      cells.push(...rowCells);

      if (rowNode.data?.diagnostics) {
        diagnostics.push(rowNode.data.diagnostics);
      }
    }
  });

  const shownMap = cells.reduce<transform.DataViewerSummary>((acc, cell) => {
    // Ignore all cells with no state or if they are in a hidden column
    if (cell.hidden) {
      return acc;
    }

    // When showChanges is active exclude cells which are in columns with no changes
    if (context.showChanges && !cell.hasChanges) {
      return acc;
    }

    switch (cell.cellState) {
      case SampleCellState.Valid:
      case SampleCellState.Updated:
        acc.valid++;
        break;
      case SampleCellState.New:
      case SampleCellState.Removed:
        acc.warning++;
        break;
      default:
        acc.error++;
        break;
    }

    if (acc.cellStates[cell.cellState] === undefined) {
      acc.cellStates[cell.cellState] = 0;
    }

    acc.cellStates[cell.cellState]++;
    return acc;
  }, transform.getDefaultDataViewerSummary());

  context.summarySubject.next({
    ...shownMap,
    rows: api.getDisplayedRowCount(),
    columns: columnApi.getDisplayedCenterColumns().length,
    diagnosticsSummaryData: getDiagnosticsSummaryData(diagnostics),
  });

  function getDiagnosticsSummaryData(
    diagnostics: TransformDTO.Diagnostics[]
  ): DiagnosticsSummaryData | undefined {
    if (!diagnostics || !diagnostics.length) {
      return undefined;
    }

    const mapping: DiagnosticSummary = {};
    const validation: DiagnosticSummary = {};

    diagnostics.forEach(rowDiagnostics => {
      if (rowDiagnostics.diagnosticRecords) {
        const mappingSummary = getDiagnosticSummary(
          rowDiagnostics.diagnosticRecords.mapping
        );
        mapping.success =
          getNumber(mapping.success) + getNumber(mappingSummary.success);
        mapping.failure =
          getNumber(mapping.failure) + getNumber(mappingSummary.failure);

        const validationSummary = getDiagnosticSummary(
          rowDiagnostics.diagnosticRecords.validation
        );
        validation.success =
          getNumber(validation.success) + getNumber(validationSummary.success);
        validation.failure =
          getNumber(validation.failure) + getNumber(validationSummary.failure);
      }
    });

    const summary = new Map<DiagnosticResultKey, DiagnosticSummary>();
    summary.set('mapping', mapping);
    summary.set('validation', validation);
    return summary;
  }

  function getDiagnosticSummary(
    diagnosticRecord: TransformDTO.DiagnosticRecord
  ): DiagnosticSummary {
    const success = diagnosticRecord.results?.successes?.actual;
    const failure = diagnosticRecord.results?.failures?.actual;

    return {
      success,
      failure,
    };
  }

  function getNumber(num: number | undefined): number {
    return num || 0;
  }
};
