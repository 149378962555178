/**
 * @deprecated Use Transform module
 */
export interface Filter {
  search: string;
  lastRun: boolean;
  filters: string[];
}

/**
 * @deprecated Use Transform module
 */
export const defaultFilter: Filter = {
  search: '',
  lastRun: false,
  filters: [],
};
